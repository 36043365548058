/** @jsx jsx */
import { LockedWord } from 'components/LockedWord'
import Link from 'next/link'
import { jsx, Box, Flex } from 'theme-ui'

const FourOhFour = () => {
  const title = "Feels like someone's taking a crowbar to my heart"
  return (
    <Flex
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: 'black',
        padding: '20px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box>
        <LockedWord title={title} fontVariant='largeHeader' lineHeight='0' margin='1.5rem 0 1.5rem 0' textColor='grey' wordGap='5rem' charGap='0em' />
      </Box>
      <Box sx={{paddingTop:'2rem'}}>
        <Link href={'/program'} scroll={false} >
          <a sx={{color:'red', textTransform:'uppercase', letterSpacing:'0.33em'}}>Return Home</a>
        </Link>
      </Box>
    </Flex>

  )
}

export default FourOhFour

